// Colours
const black = "#000000"
const white = "#ffffff"
const red = "#cc0000"
const darkRed = "#762e2a"
const blue = "#00A8D8"
const darkBlue = "#0068a5"
const orange = "#f7931d"
const darkOrange = "#c06615"
const yellow = "#fff33e"
const darkYellow = "#d7c700"
const green = "#7ac142"
const darkGreen = "#2b7c39"

const grey_100 = "#f1f1f1"
const grey_200 = "#F8F8F8"
const grey_300 = "#ECECEC"
const grey_400 = "#a6a6a6"
const grey_500 = "#979797"
const grey_600 = "#313131"
const grey_700 = "#242424"

const primary = blue
const primaryDark = darkBlue
const secondary = grey_400

const adminPrimary = "#991aed"
const adminSecondary = "#711ea8"

// Gradients
const blueGradient = "linear-gradient(to bottom, #00A8D8 0%, #0068a5 100%)"

const adminPrimaryGradient =
  "linear-gradient(to bottom, #991aed 0%, #711ea8 100%)"

// Breakpoints
const screen_xs_max = "575px"
const screen_sm_min = "576px"
const screen_sm_max = "767px"
const screen_md_min = "768px"
const screen_md_max = "991px"
const screen_lg_min = "992px"
const screen_lg_max = "1199px"
const screen_xl_min = "1200px"

// Fonts
const primaryFont = `'Poppins', sans-serif`
const secondaryFont = `'Lora', serif`
const primaryAdminFont = `'Roboto', sans-serif`

// Functions
const blackTransparent = (transparency = "0.5") =>
  `rgba(0, 0, 0, ${transparency})`

const whiteTransparent = (transparency = "0.5") =>
  `rgba(255, 255, 255, ${transparency})`

const dynamicColour = (transparency = "0.8") =>
  `rgba(255, 255, 255, ${transparency})`

export default {
  black,
  white,
  blue,
  darkBlue,
  red,
  darkRed,
  orange,
  darkOrange,
  yellow,
  darkYellow,
  green,
  darkGreen,

  primary,
  primaryDark,
  secondary,

  adminPrimary,
  adminSecondary,

  blueGradient,
  adminPrimaryGradient,

  grey_100,
  grey_200,
  grey_300,
  grey_400,
  grey_500,
  grey_600,
  grey_700,

  screen_xs_max,
  screen_sm_min,
  screen_sm_max,
  screen_md_min,
  screen_md_max,
  screen_lg_min,
  screen_lg_max,
  screen_xl_min,

  primaryFont,
  secondaryFont,
  primaryAdminFont,

  blackTransparent,
  whiteTransparent,
  dynamicColour,
}
