import React, { useRef, useLayoutEffect, useState, useEffect } from "react"
import { gsap } from "gsap"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { getProjectDetails } from "services/projectsService"
import vars from "styles/variables"
import LoadingIcon from "components/common/LoadingIcon"
import useFetch from "hooks/useFetch"
import { parallaxIt } from "utils/animationUtil"
import useLocoScroll from "hooks/useLocoScroll"

function ProjectDetailsPage() {
  const projectContainer = useRef()
  const q = gsap.utils.selector(projectContainer)

  let { slug } = useParams()
  const { data, isLoading } = useFetch(() => getProjectDetails(slug))

  useLocoScroll(!isLoading)

  useEffect(() => {
    if (!isLoading && projectContainer) {
      if (typeof window === "undefined" || !window.document) {
        return
      }
    }
  }, [isLoading])

  useLayoutEffect(() => {
    // gsap.from(q(".project-item"), {
    //   opacity: 0,
    //   yPercent: 100,
    //   duration: 1,
    //   stagger: 0.2,
    //   ease: "back.out(1.7)",
    // })

    function onMove(event) {
      parallaxIt(event, q(".project-banner"), -50)
    }

    document.addEventListener("mousemove", onMove)

    return () => {
      window.removeEventListener("mousemove", onMove)
    }
  }, [isLoading])

  if (isLoading) return <LoadingIcon isFullScreen />

  return (
    <div
      ref={projectContainer}
      className="main-container"
      id="main-container"
      data-scroll-container
    >
      <StyledBannerContainer>
        <StyledBanner src={data.banner} alt="" className="project-banner" />
      </StyledBannerContainer>
      <StyledContent>
        <h1>{data.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
      </StyledContent>
    </div>
  )
}

export default ProjectDetailsPage

const StyledContent = styled.div`
  width: 530px;
  height: 530px;
  border-radius: 50%;
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 50;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.5;
  overflow: hidden;
  background: ${vars.whiteTransparent(0.9)};
  padding: 50px;

  *:last-child {
    margin-bottom: 0;
  }
`
const StyledBannerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  clip-path: circle(60%);
`

const StyledBanner = styled.img`
  width: 100vw;
  height: calc(100vh + 100px);
  object-fit: cover;
  position: absolute;
  top: -50px;
  left: 0;
`
